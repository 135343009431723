import { gql } from '@apollo/client';

export const MAP_PROPERTIES_QUERY = gql`
  query getMapProperties(
    $topLeftLat: Float!
    $topLeftLng: Float!
    $bottomRightLat: Float!
    $bottomRightLng: Float!
    $market: String
  ) {
    mapProperties(
      topLeftLat: $topLeftLat
      topLeftLng: $topLeftLng
      bottomRightLat: $bottomRightLat
      bottomRightLng: $bottomRightLng
      market: $market
    ) {
      ticker
      coordinates {
        latitude
        longitude
      }
      address {
        street
        houseNumber
        city {
          name
        }
        state {
          code
        }
        zipCode
      }
      photos(limit: 1) {
        uri
      }
      propertyType
    }
  }
`;
