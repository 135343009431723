import { gql } from '@apollo/client';

export const MARKETS_PATHS_QUERY = gql`
  query getMarketsPaths {
    markets {
      name
      photos {
        uri
      }
      numProperties
    }
  }
`;
