import useAuth from 'context/Auth';
import useStockPrice from 'hooks/requests/properties/useStockPrice';
import useFeedItemsAuthedChooser from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsAuthedChooser';
import { useEffect, useMemo, useState } from 'react';
import { usePropertiesStore } from 'stores/Properties/properties';
import { PropertyType } from 'types/property-types';

export const FETCH_SIZE = 30;
export type FeedPropertyType = PropertyType & {
  __typename: string;
};

const useFeedItemsFetcher = (defaultFilters: Record<string, string[]>) => {
  const [initialized, setInitialized] = useState(false);
  const setProperties = usePropertiesStore((state) => state.setProperties);
  const { data, fetchNextPage, loading, hasMore } =
    useFeedItemsAuthedChooser(defaultFilters);
  const { isLoggedIn, initialized: authInitialized } = useAuth();
  const tickers = useMemo(() => data?.map((item) => item.ticker) || [], [data]);
  useStockPrice(tickers);

  useEffect(() => {
    setProperties(data);
  }, [data]);

  useEffect(() => {
    if (!authInitialized) return;
    fetchNextPage();
    setInitialized(true);
  }, [isLoggedIn, authInitialized]);

  return {
    initialized,
    loading,
    data,
    fetchNextPage,
    hasMore,
  };
};

export default useFeedItemsFetcher;
