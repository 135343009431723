import { gql } from '@apollo/client';

export const MARKETS_QUERY = gql`
  query getMarket($marketNames: [String!]) {
    markets(marketNames: $marketNames) {
      name
      photos {
        uri
      }
      description
    }
  }
`;
