import classes from './index.module.scss';

const FeedEmptyState = () => {
  return (
    <div className={classes.container}>
      We could not find anything for the current filters
    </div>
  );
};

export default FeedEmptyState;
