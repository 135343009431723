import { FeedPropertyType } from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';
import React, { HTMLAttributes, memo } from 'react';
import { HOME_FEED_ITEMS } from './utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  item: FeedPropertyType;
  isResident?: boolean;
}

const FeedItem = ({ item, isResident, ...props }: Props) => {
  const DynamicComponent = item && HOME_FEED_ITEMS[item.__typename]?.(item);

  if (!DynamicComponent) return null;
  return (
    <div {...props}>
      <DynamicComponent
        isResident={isResident}
        item={item}
      />
    </div>
  );
};

export default memo(FeedItem);
