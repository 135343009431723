import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';

export const useUrlState = <T>(initialState?: Partial<T>) => {
  const { router } = useLandaRouter();
  const state: T = router.query as T;

  const setState = async (setStateArg: ((prevState: T) => T) | T) => {
    let newState: T;
    if (typeof setStateArg === 'function') {
      newState = (setStateArg as (prevState: T) => T)(state);
    } else {
      newState = setStateArg;
    }
    await router.push({ query: newState as ParsedUrlQuery });
  };

  useEffect(() => {
    if (initialState) {
      const putInitialState = async () => {
        await router.push({ query: initialState as ParsedUrlQuery });
      };
      putInitialState().then();
    }
  }, []);

  return [state, setState] as const;
};
