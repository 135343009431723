import { MAP_PROPERTIES_QUERY } from 'graphql/properties/queries/mapPropertiesQuery';
import { useRealEstateLazyQuery } from 'hooks/api/graphql/real_estate';
import { useEffect, useState } from 'react';
import { MapPropertyType } from 'types/property-types';

const useFetchMapProperties = (variables?: { [key: string]: any }) => {
  const [mapPropertiesQuery, { loading }] =
    useRealEstateLazyQuery(MAP_PROPERTIES_QUERY);
  const [mapProperties, setMapProperties] = useState([]);

  const request = () => {
    mapPropertiesQuery({
      variables: {
        topLeftLng: 0,
        topLeftLat: 0,
        bottomRightLat: 0,
        bottomRightLng: 0,
        ...variables,
      },
    }).then((data) => {
      setMapProperties(data.data.mapProperties);
    });
  };

  useEffect(() => {
    request();
  }, []);

  return {
    loading,
    request,
    mapProperties: mapProperties as MapPropertyType[],
  };
};

export default useFetchMapProperties;
