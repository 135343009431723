import useAuth from 'context/Auth';
import { FEED_FILTER_QUERY } from 'graphql/feed/queries/feedItemsQuery';
import { useLandaApiPagination } from 'hooks/api/graphql/landa_api';
import { useRealEstatePagination } from 'hooks/api/graphql/real_estate';
import {
  FeedPropertyType,
  FETCH_SIZE,
} from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';
import useFeedFilters from 'pages/feed/hooks/useFeedFilters';

const UseFeedItemsAuthedChooser = (
  defaultFilters?: Record<string, string[]>
) => {
  const { filters } = useFeedFilters();
  const { isLoggedIn } = useAuth();

  const realEstatePagination = useRealEstatePagination<FeedPropertyType>(
    FEED_FILTER_QUERY,
    (data) => data?.homeFeedFilter,
    { variables: { ...filters, ...defaultFilters } },
    FETCH_SIZE
  );

  const apiPagination = useLandaApiPagination<FeedPropertyType>(
    FEED_FILTER_QUERY,
    (data) => data?.homeFeedFilter,
    { variables: { ...filters, ...defaultFilters } },
    FETCH_SIZE
  );

  return isLoggedIn ? apiPagination : realEstatePagination;
};

export default UseFeedItemsAuthedChooser;
