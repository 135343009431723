import { gql } from '@apollo/client';

export const FEED_FILTER_QUERY = gql`
  query feedItemsQuery(
    $first: Int!
    $after: String
    $categories: [String]
    $offeringStatuses: [String]
    $propertyTypes: [String]
    $occupancyStatuses: [String]
    $markets: [String]
  ) {
    homeFeedFilter(
      first: $first
      after: $after
      categories: $categories
      offeringStatuses: $offeringStatuses
      propertyTypes: $propertyTypes
      occupancyStatuses: $occupancyStatuses
      markets: $markets
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      reachedEnd
      edges {
        cursor
        node {
          __typename
          ... on PropertyType {
            ticker
            ownershipStatus
            propertyType
            unitsCount
            vacantUnitsCount
            remainingShares
            sqf
            beds
            baths
            floors
            initialSharesQuantity
            coordinates {
              latitude
              longitude
            }
            units {
              occupancyStatus
              proposedRent
            }
            address {
              houseNumber
              street
              city {
                name
              }
              state {
                code
                name
              }
              zipCode
            }
            photos {
              uri
            }
          }
          ... on CityType {
            name
            isComingSoon
            isNew
            photos {
              uri
            }
            tickers
          }
          ... on CarouselHomeItemType {
            itemsType
            title
            items {
              ... on PropertyType {
                __typename
                ticker
                ownershipStatus
                propertyType
                unitsCount
                vacantUnitsCount
                remainingShares
                baths
                floors
                beds
                sqf
                initialSharesQuantity
                coordinates {
                  latitude
                  longitude
                }
                units {
                  occupancyStatus
                }
                address {
                  houseNumber
                  street
                  city {
                    name
                  }
                  state {
                    code
                    name
                  }
                  zipCode
                }
                photos {
                  uri
                }
              }
              ... on CityType {
                __typename
                name
                isComingSoon
                isNew
                photos {
                  uri
                }
                tickers
              }
            }
          }
        }
      }
    }
  }
`;
