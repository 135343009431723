import { gql } from '@apollo/client';

export const HOME_MARKET_QUERY = gql`
  query HomeMarket($first: Int!, $after: String, $market: String!) {
    homeMarket(first: $first, after: $after, market: $market) {
      totalCount
      reachedEnd
      edges {
        cursor
        node {
          __typename
          ... on PropertyType {
            ticker
            coordinates {
              latitude
              longitude
            }
            ownershipStatus
            propertyType
            unitsCount
            vacantUnitsCount
            remainingShares
            initialSharesQuantity
            units {
              occupancyStatus
            }
            address {
              houseNumber
              street
              city {
                name
              }
              state {
                code
                name
              }
              zipCode
            }
            photos {
              uri
            }
          }
          ... on CityType {
            name
            isComingSoon
            isNew
            photos {
              uri
            }
            tickers
          }
          ... on CarouselHomeItemType {
            itemsType
            carouselType
            title
            items {
              ... on PropertyType {
                __typename
                ticker
                ownershipStatus
                propertyType
                unitsCount
                vacantUnitsCount
                remainingShares
                initialSharesQuantity
                units {
                  occupancyStatus
                }
                address {
                  houseNumber
                  street
                  city {
                    name
                  }
                  state {
                    code
                    name
                  }
                  zipCode
                }
                photos {
                  uri
                }
              }
              ... on CityType {
                __typename
                name
                isComingSoon
                isNew
                photos {
                  uri
                }
                tickers
              }
            }
          }
        }
      }
    }
  }
`;
