import { FilterTypeKey } from 'consts/enums/property';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { useUrlState } from 'hooks/logics/useUrlState';
import flatten from 'lodash/flatten';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import { arrayify } from 'utils/helpers';

export type FilterTypes = {
  [key in FilterTypeKey]: string[];
};
export type FilterType = keyof FilterTypes;

export const initialFilters = {
  [FilterTypeKey.CATEGORIES]: [],
  [FilterTypeKey.PROPERTY_TYPES]: [],
  [FilterTypeKey.OFFERING_STATUSES]: [],
  [FilterTypeKey.OCCUPANCY_STATUSES]: [],
  [FilterTypeKey.MARKETS]: [],
};

const useFeedFilters = () => {
  const { router } = useLandaRouter();
  const [state, setState] = useUrlState<FilterTypes>();
  const initialized = router.isReady;

  const setFilters = async (filters: FilterTypes) => {
    await setState(mapValues(filters, (value) => value?.sort()));
  };

  const resetFilterType = async (filterType: FilterType) => {
    await setState((state) => {
      return { ...state, [filterType]: [] };
    });
  };

  const resetAllFilters = async (exclude: FilterTypeKey[]) => {
    const relevantKeys = Object.keys(
      omit(initialFilters, exclude || [])
    ) as FilterTypeKey[];

    for (const key of relevantKeys) {
      state[key] = initialFilters[key];
    }
    await setState(state);
  };

  const addOrRemove = async (filterType: FilterType, value: string) => {
    await setState((state) => {
      const current = arrayify(state[filterType]);
      return {
        ...state,
        [filterType]: current?.addOrRemove?.(value)?.sort?.(),
      };
    });
  };

  const getFiltersCount = (exclude: FilterTypeKey[], filters?: FilterTypes) => {
    const relevantObject = omit(filters || state, exclude || []);
    return flatten(Object.values(relevantObject)).length;
  };

  Object.keys(state).forEach((key) => {
    state[key as FilterTypeKey] = arrayify(state[key as FilterTypeKey]);
  });

  return {
    initialized,
    filters: state,
    setFilters,
    resetFilterType,
    resetAllFilters,
    addOrRemove,
    getFiltersCount,
  };
};

export default useFeedFilters;
