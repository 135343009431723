import FeedPropertyCard from 'components/properties/PropertyCard/FeedPropertyCard';
import { ElementType } from 'react';

export const HOME_FEED_ITEM_TYPES = {
  PROPERTY_TYPE: 'PropertyType',
};

export const HOME_FEED_ITEMS: { [key: string]: (item: object) => ElementType } =
  {
    [HOME_FEED_ITEM_TYPES.PROPERTY_TYPE]: () => FeedPropertyCard,
  };
