import { HOME_MARKET_QUERY } from 'graphql/feed/queries/homeMarketQuery';
import { MARKETS_QUERY } from 'graphql/market/query/marketQuery';
import { MARKETS_PATHS_QUERY } from 'graphql/market/query/marketsPathsQuery';
import { Edge } from 'hooks/api/graphql/functions/useLandaPagination';
import { realEstateGraphqlFetcher } from 'hooks/api/SWR/graphql/fetchers/realEstateGraphqlFetcher';
import { GetStaticProps } from 'next';
import {
  FeedPropertyType,
  FETCH_SIZE,
} from 'pages/feed/components/PropertiesContainer/hooks/useFeedItemsFetcher';

export const decodeMarket = (market: string) => {
  return market.replace(/_/g, ' ');
};

export const encodeMarket = (market: string) => {
  return market.replace(/\s/g, '_');
};

export const getStaticPropsMarketPage: GetStaticProps = async (context) => {
  const marketName = context.params?.market as string;
  const marketResponse = await realEstateGraphqlFetcher([
    MARKETS_QUERY,
    { marketNames: [decodeMarket(marketName)] },
    {},
  ]);
  const market = marketResponse?.markets[0];

  const response = await realEstateGraphqlFetcher([
    HOME_MARKET_QUERY,
    { first: FETCH_SIZE, market: decodeMarket(marketName) },
    {},
  ]);

  const properties =
    response?.homeMarket?.edges?.map(
      (edge: Edge<FeedPropertyType>) => edge?.node as FeedPropertyType
    ) || [];

  return {
    props: {
      market,
      properties,
    },
  };
};

export const getStaticPathsMarketPage = async () => {
  const marketResponse = await realEstateGraphqlFetcher([
    MARKETS_PATHS_QUERY,
    {},
    {},
  ]);
  const markets = marketResponse?.markets;
  const paths = markets?.map((market: { name: string }) => {
    return {
      params: {
        market: encodeMarket(market.name),
      },
    };
  });

  return {
    paths: paths,
    fallback: true,
  };
};
